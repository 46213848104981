<template>
  <div class="gallery-map wh-100">
    <div class="gallery-map-wrapper" :style="`--max: ${max}px;`">
      <div class="rows" v-if="dots && max">
        <div
          v-for="(row, y) in dots" :key="y"
          class="row"
          :class="{ sub: y % 2 === 0 }"
        >
          <div
            v-for="(dot, x) in row" :key="`${y}-${x}`"
            class="dot" :class="{ current: dot === node }"
          >
            <img
              v-if="dot"
              :src="dot.image.url"
              :alt="dot.image.alt"
              @click="$emit('open-creation', dot.id)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shuffle } from '@/helpers/common'


export default {
  name: 'GalleryMap',

  props: {
    nodes: { type: Array, required: true },
    node: { type: Object, required: true },
    visible: { type: Boolean, required: true }
  },

  data () {
    return {
      dots: undefined,
      size: [0, 0],
      max: undefined
    }
  },

  watch: {
    visible (visible) {
      if (visible) {
        this.defineSize()
      }
    }
  },

  methods: {
    setDots () {
      const nodesLen = this.nodes.length
      const sqrt = Math.ceil(Math.sqrt(nodesLen))
      const rows = Math.floor(Math.sqrt(Math.pow(sqrt, 2) / 2))
      const len = rows + 1
      let max = rows * len * 2
      let extra = 0
      while (max - nodesLen < 0) {
        extra += 1
        max += extra % 2 === 0 ? rows : len
      }

      const nodes = shuffle([...this.nodes, ...Array(max - nodesLen).fill(undefined)])
      const dots = []
      for (var y = 0; y < len + rows + extra; y++) {
        const x = y % 2 === 0 ? rows : len
        dots.push(nodes.splice(0, x))
      }
      this.size = [len, len + rows + extra]
      this.dots = dots
    },

    defineSize () {
      const [x, y] = this.size
      const { offsetWidth, offsetHeight } = document.querySelector('.gallery-map-wrapper')
      const h = offsetHeight / (y * 2 - 1)
      const w = offsetWidth / (x * 2 - 1)
      this.max = Math.min(Math.min(h, w), 100)
    }
  },

  created () {
    this.setDots()
    if (this.visible) {
      this.$nextTick().then(() => {
        this.defineSize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-map {

  &-wrapper {
    width: 100%;
    position: relative;
    height: 100%;
  }

  .rows {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.sub {
      justify-content: space-around;
    }
  }

  .dot {
    display: flex;
    width: var(--max);
    height: var(--max);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      cursor: pointer;
    }

    &.current img {
      border: 5px solid theme-color('creation');
    }
  }
}
</style>
