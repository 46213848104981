<template>
  <div class="gallery-view wh-100">
    <div class="gallery-view-wrapper wh-100">
      <figure class="gallery-view-figure">
        <div class="h-100" />

        <div class="node-view-img-wrapper">
          <img :src="node.image.url" :alt="node.image.alt">

          <button-expand
            v-if="node.iframeUrl || node.mediaItem" artwork
            v-b-modal="'modal-creation' + node.id" class="center"
          />

          <div class="btns-artwork">
            <b-button variant="depart" class="btn-shadow btn-artwork mr-2" @click="$emit('view-origin')">
              {{ $t('text.open-origin') }}
            </b-button>

            <b-button variant="creation" class="btn-shadow btn-artwork" v-b-toggle.gallery-text>
              {{ $t('text.open-artwork') }}
            </b-button>
          </div>
        </div>

        <figcaption class="gallery-view-caption">
          <node-view-title :node="node" tag="p" class="mb-0 mt-4" />
        </figcaption>
      </figure>

      <div
        v-for="(sibling, i) in node.creation_siblings" :key="sibling.id"
        class="gallery-view-btn-wrapper"
        :class="buttonClasses[i]"
      >
        <button-image :image="sibling.image" @click="$emit('open-creation', sibling.id)">
          <node-view-title
            :node="sibling"
            tag="span" link
            class="sr-only"
          />
        </button-image>
      </div>

      <side-view id="gallery-text" right no-cross>
        <template #default="{ hide }">
          <node-view
            :node="node"
            @close-node="hide"
            @open-node="$emit('view-origin')"
            force-type="ref" show-origin
          />
        </template>
      </side-view>
    </div>
  </div>
</template>

<script>
import { NodeViewTitle, NodeView } from '@/components/nodes'
import { SideView } from '@/components/layouts'
import { trim, toCommaList, shuffle } from '@/helpers/common'

export default {
  name: 'GalleryView',

  components: {
    NodeViewTitle,
    NodeView,
    SideView
  },

  props: {
    node: { type: Object, default: undefined }
  },

  data () {
    return {
      image: undefined,
      buttonClasses: [
        ['top', 'left'],
        ['top', 'right'],
        ['bottom', 'right'],
        ['bottom', 'left']
      ]
    }
  },

  methods: {
    trim,
    toCommaList
  },

  created () {
    this.buttonClasses = shuffle(this.buttonClasses)
  }
}
</script>

<style lang="scss" scoped>
.gallery-view {
  margin: auto;

  &-wrapper {
    display: flex;
  }

  &-figure {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 4rem);
    margin: auto;

    width: calc(100% - 4rem);

    @media (orientation: landscape) {
      width: calc(100% - 270px);

      @include media-breakpoint-up($size-bp) {
        width: calc(100% - 500px);
      }
    }

    .node-view-img-wrapper {
      flex-basis: 100%;
      flex-grow: 2;
      height: calc(100% - 270px - 4rem);

      @media (orientation: landscape) {
        height: calc(100% - 270px);
        @include media-breakpoint-up($size-bp) {
          height: calc(100% - 450px);
        }
      }
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    .btns-artwork {
      position: absolute;

      @include media-breakpoint-down($size-bp-down) {
        top: -50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      @include media-breakpoint-up($size-bp) {
        right: $node-view-spacer-sm-x;
        bottom: $node-view-spacer-sm-y;
      }
    }
  }

  &-caption {
    height: 100%;
    text-align: center;
    font-family: $font-family-text;

    @include media-breakpoint-up($size-bp) {
      font-size: 1.5rem;
    }
  }

  .gallery-view-btn-wrapper {
    position: absolute;
    overflow: hidden;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
  }
}

::v-deep #gallery-text {
  @include media-breakpoint-up($size-bp) {
    width: 100%;
  }

  .node-view {
    min-height: 100%;

    &-wrapper {
      min-height: 100%;
    }
  }
}
</style>
